// Sidebar
export const SET_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_TOGGLE_MOBILE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE';
export const SET_SIDEBAR_FIXED = 'THEME_OPTIONS/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_FOOTER = 'THEME_OPTIONS/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_USERBOX = 'THEME_OPTIONS/SET_SIDEBAR_USERBOX';
export const SET_SIDEBAR_HOVER = 'THEME_OPTIONS/SET_SIDEBAR_HOVER';
// HEADER
export const SET_HEADER_FIXED = 'THEME_OPTIONS/SET_HEADER_FIXED';
export const SET_HEADER_SHADOW = 'THEME_OPTIONS/SET_HEADER_SHADOW';
export const SET_HEADER_SEARCH_HOVER = 'THEME_OPTIONS/SET_HEADER_SEARCH_HOVER';
// Main content
export const SET_CONTENT_BACKGROUND = 'THEME_OPTIONS/SET_CONTENT_BACKGROUND';
export const SET_THEME_CONFIGURATOR_TOGGLE = 'THEME_OPTIONS/SET_THEME_CONFIGURATOR_TOGGLE';
// Footer
export const SET_FOOTER_FIXED = 'THEME_OPTIONS/SET_FOOTER_FIXED';
export const SET_FOOTER_SHADOW = 'THEME_OPTIONS/SET_FOOTER_SHADOW';
// Page title
export const SET_PAGE_TITLE_STYLE = 'THEME_OPTIONS/SET_PAGE_TITLE_STYLE';
export const SET_PAGE_TITLE_BACKGROUND = 'THEME_OPTIONS/SET_PAGE_TITLE_BACKGROUND';
export const SET_PAGE_TITLE_SHADOW = 'THEME_OPTIONS/SET_PAGE_TITLE_SHADOW';
export const SET_PAGE_TITLE_BREADCRUMB = 'THEME_OPTIONS/SET_PAGE_TITLE_BREADCRUMB';
export const SET_PAGE_TITLE_ICON_BOX = 'THEME_OPTIONS/SET_PAGE_TITLE_ICON_BOX';
export const SET_PAGE_TITLE_DESCRIPTION = 'THEME_OPTIONS/SET_PAGE_TITLE_DESCRIPTION';
export const setSidebarShadow = (payload) => ({
    type: SET_SIDEBAR_SHADOW,
    payload
});
export const setSidebarFixed = (payload) => ({
    type: SET_SIDEBAR_FIXED,
    payload
});
export const setSidebarToggleMobile = (payload) => ({
    type: SET_SIDEBAR_TOGGLE_MOBILE,
    payload
});
export const setSidebarFooter = (payload) => ({
    type: SET_SIDEBAR_FOOTER,
    payload
});
export const setSidebarToggle = (payload) => ({
    type: SET_SIDEBAR_TOGGLE,
    payload
});
export const setSidebarHover = (payload) => ({
    type: SET_SIDEBAR_HOVER,
    payload
});
export const setSidebarUserbox = (payload) => ({
    type: SET_SIDEBAR_USERBOX,
    payload
});
// Header
export const setHeaderFixed = (payload) => ({
    type: SET_HEADER_FIXED,
    payload
});
export const setHeaderShadow = (payload) => ({
    type: SET_HEADER_SHADOW,
    payload
});
export const setHeaderSearchHover = (payload) => ({
    type: SET_HEADER_SEARCH_HOVER,
    payload
});
// Main content
export const setContentBackground = (payload) => ({
    type: SET_CONTENT_BACKGROUND,
    payload
});
export const setThemeConfiguratorToggle = (payload) => ({
    type: SET_THEME_CONFIGURATOR_TOGGLE,
    payload
});
// Footer
export const setFooterFixed = (payload) => ({
    type: SET_FOOTER_FIXED,
    payload
});
export const setFooterShadow = (payload) => ({
    type: SET_FOOTER_SHADOW,
    payload
});
// Page title
export const setPageTitleStyle = (payload) => ({
    type: SET_PAGE_TITLE_STYLE,
    payload
});
export const setPageTitleBackground = (payload) => ({
    type: SET_PAGE_TITLE_BACKGROUND,
    payload
});
export const setPageTitleShadow = (payload) => ({
    type: SET_PAGE_TITLE_SHADOW,
    payload
});
export const setPageTitleBreadcrumb = (payload) => ({
    type: SET_PAGE_TITLE_BREADCRUMB,
    payload
});
export const setPageTitleIconBox = (payload) => ({
    type: SET_PAGE_TITLE_ICON_BOX,
    payload
});
export const setPageTitleDescription = (payload) => ({
    type: SET_PAGE_TITLE_DESCRIPTION,
    payload
});
const initialState = {
    // Sidebar
    sidebarShadow: false,
    sidebarFixed: true,
    sidebarToggleMobile: false,
    sidebarFooter: true,
    sidebarUserbox: true,
    sidebarToggle: false,
    sidebarHover: false,
    // Header
    headerFixed: true,
    headerShadow: true,
    headerSearchHover: false,
    headerDrawerToggle: false,
    // Main content
    contentBackground: '',
    themeConfiguratorToggle: false,
    // Footer
    footerFixed: false,
    footerShadow: false,
    // Page title
    pageTitleStyle: '',
    pageTitleBackground: '',
    pageTitleShadow: false,
    pageTitleBreadcrumb: false,
    pageTitleIconBox: true,
    pageTitleDescription: true
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        // Sidebar
        case SET_SIDEBAR_SHADOW:
            return Object.assign(Object.assign({}, state), { sidebarShadow: action.payload });
        case SET_SIDEBAR_FIXED:
            return Object.assign(Object.assign({}, state), { sidebarFixed: action.payload });
        case SET_SIDEBAR_TOGGLE_MOBILE:
            return Object.assign(Object.assign({}, state), { sidebarToggleMobile: action.payload });
        case SET_SIDEBAR_FOOTER:
            return Object.assign(Object.assign({}, state), { sidebarFooter: action.payload });
        case SET_SIDEBAR_TOGGLE:
            return Object.assign(Object.assign({}, state), { sidebarToggle: action.payload });
        case SET_SIDEBAR_HOVER:
            return Object.assign(Object.assign({}, state), { sidebarHover: action.payload });
        case SET_SIDEBAR_USERBOX:
            return Object.assign(Object.assign({}, state), { sidebarUserbox: action.payload });
        // Header
        case SET_HEADER_FIXED:
            return Object.assign(Object.assign({}, state), { headerFixed: action.payload });
        case SET_HEADER_SHADOW:
            return Object.assign(Object.assign({}, state), { headerShadow: action.payload });
        case SET_HEADER_SEARCH_HOVER:
            return Object.assign(Object.assign({}, state), { headerSearchHover: action.payload });
        // Main content
        case SET_CONTENT_BACKGROUND:
            return Object.assign(Object.assign({}, state), { contentBackground: action.payload });
        case SET_THEME_CONFIGURATOR_TOGGLE:
            return Object.assign(Object.assign({}, state), { themeConfiguratorToggle: action.payload });
        // Footer
        case SET_FOOTER_FIXED:
            return Object.assign(Object.assign({}, state), { footerFixed: action.payload });
        case SET_FOOTER_SHADOW:
            return Object.assign(Object.assign({}, state), { footerShadow: action.payload });
        // Page title
        case SET_PAGE_TITLE_STYLE:
            return Object.assign(Object.assign({}, state), { pageTitleStyle: action.payload });
        case SET_PAGE_TITLE_BACKGROUND:
            return Object.assign(Object.assign({}, state), { pageTitleBackground: action.payload });
        case SET_PAGE_TITLE_SHADOW:
            return Object.assign(Object.assign({}, state), { pageTitleShadow: action.payload });
        case SET_PAGE_TITLE_BREADCRUMB:
            return Object.assign(Object.assign({}, state), { pageTitleBreadcrumb: action.payload });
        case SET_PAGE_TITLE_ICON_BOX:
            return Object.assign(Object.assign({}, state), { pageTitleIconBox: action.payload });
        case SET_PAGE_TITLE_DESCRIPTION:
            return Object.assign(Object.assign({}, state), { pageTitleDescription: action.payload });
        default:
            break;
    }
    return state;
}