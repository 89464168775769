import React, { useEffect } from "react";
import Formulario from "./Formulario";
import ResourceRender from "ghost-stories/dist/react/resource";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_BUSCAR_USER } from "../../../constants/codes";
import { ListarDatosUsuario } from "../../../redux/actions";
import { FIX_URL } from "ghost-stories/dist/constants";
import { OnQueryUser, OnError, OnEmpty } from "../../ResourceHelpers";
import { useParams } from "react-router-dom";

const CargaDeDatos = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cod = "?code=" + CODIGO_BUSCAR_USER;
  const usuario = useSelector((state) => state.clientes.usuario);

  useEffect(() => {
    dispatch(
      ListarDatosUsuario(usuario.update({ [FIX_URL]: cod, ClientCI: id }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ResourceRender
        resource={usuario}
        Data={Formulario}
        Query={OnQueryUser}
        Empty={OnError}
        Error={OnEmpty}
        props={{ cedula: id }}
      />
    </>
  );
};

export default CargaDeDatos;
