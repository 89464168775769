import React, { Fragment } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Logo from "../../../../assets/images/avatarF.png";

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover,
        })}
      >
        <Box className="header-logo-wrapper">
          <Link to="/dashboard" className="header-logo-wrapper-link">
            <div className="avatar-icon-wrapper avatar-icon-sm">
              <div className="avatar-icon">
                <img alt="..." src={Logo} />
              </div>
            </div>
          </Link>
          <Box className="header-logo-text">Fortaleza</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);
