import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { Box, Button, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import AvatarF from "../../../../assets/images/avatars/avatar.png";

const SidebarUserbox = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover,
        })}
      >
        <Avatar
          alt="Remy Sharp"
          src={AvatarF}
          onClick={toggle1}
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box className="mb-2"></Box>
          <Box className="app-sidebar-userbox-btn-profile"></Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserbox);
