import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import clsx from "clsx";

const Footer = (props) => {
  const { footerShadow, sidebarToggle, footerFixed } = props;
  return (
    <Fragment>
      <Paper
        square
        elevation={footerShadow ? 11 : 2}
        className={clsx("app-footer text-black-50", {
          "app-footer--fixed": footerFixed,
          "app-footer--fixed__collapsed": sidebarToggle,
        })}
      >
        <div className="app-footer--inner">
          <div className="app-footer--second">
            <a href="#/" title="UiFort.com">
              Gestión de documentos Fortaleza.
            </a>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});
export default connect(mapStateToProps)(Footer);
