// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT: "/root",
  SIGN_UP: "/signup",
  LOG_IN: "/login",
  LANDING: "/landing",
  E404: "/error404",
  DASHBOARD: "/dashboard",
  ACCESO: "/",
  CARGA_DE_DATOS: "/carga_datos/:id",
};
