// lib imports
import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { PrivateRoute } from "./components/utils";

// styles

import MuiTheme from "./theme";

import ROUTES from "./constants/routes";
import { ROLES_ALL, ROL_CLIENTE } from "./constants/roles";

//pages - components
// import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import Acceso from "./pages/PuntoAcceso";
import CargaDeDatos from "./pages/PuntoAcceso/CargaDeDatos";

// helpers
import MinimalLayout from "./layouts/Minimal";
import LeftSidebar from "./layouts/LeftSidebar";

const ROUTES_COMPONENTS = [
  [ROUTES.DASHBOARD, Dashboard, ROLES_ALL],
  [ROUTES.ACCESO, Acceso, ROL_CLIENTE],
  [ROUTES.CARGA_DE_DATOS, CargaDeDatos, ROL_CLIENTE],
];

const PRIVATE_ROUTES = ROUTES_COMPONENTS.map(([route, _]) => route);

// I want dashboard to only be accesible fo
export const Routes = (props) => {
  const location = useLocation();
  // const { usuario } = props;
  // const user = useSelector((state) => state.authentication.user);
  // const loggedIn = usuario != null;
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Redirect exact from={ROUTES.ROOT} to={ROUTES.ACCESO} />
        <Route path={[ROUTES.E404]}>
          <MinimalLayout>
            <Switch location={location} key={location.pathname}>
              <Route
                path={ROUTES.E404}
                component={() => <div>'This is the error page'</div>}
              />
            </Switch>
          </MinimalLayout>
        </Route>

        <Route exact path={PRIVATE_ROUTES}>
          <LeftSidebar>
            <Switch location={location} key={location.pathname}>
              {ROUTES_COMPONENTS.map(([route, Component, roles], index) => (
                <PrivateRoute
                  key={index.toString()}
                  exact
                  roles={roles}
                  path={route}
                  component={Component}
                />
              ))}
            </Switch>
          </LeftSidebar>
        </Route>
      </Switch>
    </ThemeProvider>
  );
};
