import StarIcon from "@material-ui/icons/Star";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    content: [
      {
        label: "Cliente",
        icon: StarIcon,
        content: [
          {
            label: "Punto de acceso",
            to: "/",
          },
        ],
      },
    ],
  },
];
