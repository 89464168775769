import React, { Fragment } from "react";
import { ClimbingBoxLoader } from "react-spinners";

export const LoadingUser = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={"#009B86"} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Espere mientras cargamos sus datos
          {/* <span className="font-size-lg d-block text-dark">
            This live preview instance can be slower than a real production
            build!
          </span> */}
        </div>
      </div>
    </Fragment>
  );
};
