import React from "react";
import { Grid } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Dropzone from "react-dropzone";

const UploadDocument = (props) => {
  const { titulo, sent, onDrop } = props;

  return (
    <>
      <p className="text-center pt-3 text-black-100 ">{titulo}</p>

      {sent === true ? (
        <div className="w-100">
          <MuiAlert severity="success">¡Ya cargó este dato!</MuiAlert>
        </div>
      ) : (
        <Grid container spacing={4} className="mt-2">
          <Grid item xs={12} sm={12}>
            <div className="dropzone">
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-message">
                      <div className="dx-text">
                        Arrastre el archivo aquí o haga clic para seleccionar el
                        archivo que desea cargar.
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="w-100">
              {/* {state.files.length > 0 && (
                <List>
                  <ListItem className="font-weight-bold text-center">
                    Archivo seleccionado
                  </ListItem>
                  {files}
                </List>
              )} */}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UploadDocument;
