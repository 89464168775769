import React, { useCallback, useEffect, useState } from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
import UploadDocument from "./Components/UploadDocument";
import { CrearCaso, ModificarDocumentosUsuario } from "../../../redux/actions";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { FIX_URL } from "ghost-stories/dist/constants";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_MODIFICAR_DOCUMENTO } from "../../../constants/codes";

const Formulario = (props) => {
  const { value, cedula } = props;
  const dispatch = useDispatch();
  const agregar = useSelector((state) => state.caso.agregar);
  const cod = "?code=" + CODIGO_MODIFICAR_DOCUMENTO;
  const valor = value.data.map((x) => x.ClientRequestedDocuments);
  const valorEnJson = JSON.parse(valor[0]);
  const valorFiltrado = valorEnJson.filter((x) => x.estado === true);
  const [array, setArray] = useState(valorFiltrado);
  const userCI = cedula;
  const Nombre = value.data[0].ClientName;
  const Apellido = value.data[0].ClientLastName;
  const documentos = JSON.parse(value.data[0].ClientRequestedDocuments);
  const docSolicitados = documentos.filter((x) => x.estado === true).length;
  const docSubidos = documentos.filter((x) => x.sent === true);
  const porcentaje = (docSubidos.length / docSolicitados) * 100;

  const onDrop = useCallback(
    (x, index) => (event) => {
      const newArr = [...array];
      newArr[index] = {
        titulo: newArr[index].titulo,
        estado: true,
        sent: true,
        fileURL:
          "https://fortaleza.blob.core.windows.net/documents/" +
          `${userCI}` +
          "/" +
          `${x.titulo}` +
          " " +
          `${event[0].name}`,
      };
      setArray(newArr);
      dispatch(
        ModificarDocumentosUsuario(
          Resource.Query({
            [FIX_URL]: `${userCI}` + cod,
            ClientRequestedDocuments: JSON.stringify(newArr),
          })
        )
      );
      var myHeaders = new Headers();
      myHeaders.append("type", "file");
      var formdata = new FormData();
      formdata.append("image", event[0]);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        "https://az-fz-gesdoc.azurewebsites.net/api/documents/upload?code=JEZbR8gzYrEjcCPH0Y2ooQAUBqBd9GxCB6mrAEvZ2ZOXQL2UiIBOKA==&userID=" +
          `${userCI}` +
          "&docName=" +
          `${x.titulo}`,
        requestOptions
      ).then((response) => response.text());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    if (porcentaje.toFixed() === "100") {
      dispatch(
        CrearCaso(
          agregar.update({
            CustomerID: "0000000",
            CustomerCI: "0000000",
            CaseTitle:
              "Actualizacion de Documentos de " + Nombre + " " + Apellido,
            CaseBody:
              Nombre +
              " " +
              Apellido +
              " con CI nro. " +
              cedula +
              " completó el envío de documentos solicitados por el vendedor.",
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Card className="card-box mb-4">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <h6 className="font-size-lg mt-2 text-dark">Subir Documentos</h6>
          </div>
        </div>
        <CardContent>
          {valorFiltrado.map((x, i) => (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <UploadDocument
                  titulo={x.titulo}
                  sent={x.sent}
                  onDrop={onDrop(x, i)}
                />
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
    </form>
  );
};

export default Formulario;
