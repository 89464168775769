import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Header, Sidebar, Footer } from './components';
import SnackBar from '../components/SnackBar';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { children } = props;

  const {
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground
  } = useSelector((state) => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarFixed: state.ThemeOptions.sidebarFixed,

    headerFixed: state.ThemeOptions.headerFixed,
    headerSearchHover: state.ThemeOptions.headerSearchHover,
    headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

    footerFixed: state.ThemeOptions.footerFixed,

    contentBackground: state.ThemeOptions.contentBackground
  }));

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <Header />
      <div
        className={clsx('app-main', {
          'app-main-sidebar-static': !sidebarFixed
        })}>
        <Sidebar />
        <div
          className={clsx('app-content', {
            'app-content-sidebar-collapsed': sidebarToggle,
            'app-content-sidebar-fixed': sidebarFixed,
            'app-content-footer-fixed': footerFixed
          })}>
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">
              {children}
              <SnackBar />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
