import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import {
  LISTAR_DATOS_USUARIO,
  MODIFICAR_DOCUMENTOS_USUARIO,
} from "../actionTypes";
import {
  ListarDatosUsuario,
  ModificarDocumentosUsuario,
  MostrarMensaje,
} from "../actions";
import { capturarDatosUsuario, editarDocumentoUsuario } from "../api";
import { Resource } from "ghost-stories/dist/unions/Resource";

const solicitarClienteUser = (action$) =>
  action$.pipe(
    ofType(LISTAR_DATOS_USUARIO),
    toParams,
    makeRequest(capturarDatosUsuario),
    map(ListarDatosUsuario)
  );

const editarDocumentosUsuario = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTOS_USUARIO),
    toParams,
    makeRequest(editarDocumentoUsuario),
    map(ModificarDocumentosUsuario)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTOS_USUARIO),
    filter((action) => Resource.isData(action.payload)),
    mergeMap(() => [window.location.reload(false)])
  );

const onSaveEditError = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTOS_USUARIO),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      MostrarMensaje({
        open: true,
        mensaje: "No se pudo subir el documento",
        tipo: "error",
      }),
    ])
  );

export default combineEpics(
  solicitarClienteUser,
  editarDocumentosUsuario,
  onSaveEdit,
  onSaveEditError
);
