import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { CrearCaso } from "../actions";
import { CREAR_CASO } from "../actionTypes";
import { crearCaso } from "../api";

const crearCasos = (action$) =>
  action$.pipe(
    ofType(CREAR_CASO),
    toParams,
    makeRequest(crearCaso),
    map(CrearCaso)
  );

export default combineEpics(crearCasos);
