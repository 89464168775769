import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Routes } from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import '../src/assets/base.scss';
import createStore from './redux';
import './fontAwesomeSetup';
const App = () => {
  return (
    <Provider store={createStore()}>
      <BrowserRouter>
        <CssBaseline />
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
