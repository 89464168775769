import React, { useEffect } from "react";
import ResourceRender from "ghost-stories/dist/react/resource";
import { useDispatch, useSelector } from "react-redux";
import { CODIGO_BUSCAR_USER } from "../../../constants/codes";
import { ListarDatosUsuario } from "../../../redux/actions";
import { ControlAcceso } from "./ControlAcceso";
import { FIX_URL } from "ghost-stories/dist/constants";
import { OnQueryUser, OnError, OnEmpty } from "../../ResourceHelpers";

const Acesso = (props) => {
  const { cedula } = props;
  const dispatch = useDispatch();
  const cod = "?code=" + CODIGO_BUSCAR_USER;
  const usuario = useSelector((state) => state.clientes.usuario);

  useEffect(() => {
    dispatch(
      ListarDatosUsuario(usuario.update({ [FIX_URL]: cod, ClientCI: cedula }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ResourceRender
        resource={usuario}
        Data={ControlAcceso}
        Query={OnQueryUser}
        Empty={OnError}
        Error={OnEmpty}
        props={{ cedula: cedula }}
      />
    </>
  );
};

export default Acesso;
