import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../constants/routes";

export const ControlAcceso = (props) => {
  const { value, cedula } = props;
  const history = useHistory();
  const [codigo, setCodigo] = useState("");
  const [valid, setValid] = useState(false);
  const Pin = value.data.map((x) => x.ClientPIN);

  const onChangeField = (event) => {
    setCodigo(event.target.value);
    setValid(false);
  };

  const onIngresar = (event) => {
    event.preventDefault();
    if (codigo !== Pin[0]) {
      setValid(true);
    } else {
      setValid(false);
      history.push(`${ROUTES.CARGA_DE_DATOS.replace(":id", cedula)}`);
    }
  };

  return (
    <form onSubmit={onIngresar}>
      <Card>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={["fas", "key"]}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4 mb-3">
            Introduzca el pin proveído por el vendedor para acceder.
          </h4>
          <TextField
            error={valid}
            helperText={valid ? "El PIN es incorrecto" : ""}
            className="mb-3"
            label="PIN"
            onChange={onChangeField}
            variant="outlined"
            required
          />
          <div className="pt-4">
            <Button
              color="secondary"
              variant="contained"
              className="mx-1"
              type="submit"
            >
              <span className="btn-wrapper--label">Ingresar</span>
            </Button>
          </div>
        </div>
      </Card>
    </form>
  );
};
