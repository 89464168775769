import React, { Fragment } from 'react';
import {
  Popover
} from '@material-ui/core';
const HeaderMenu = () => {
  const [anchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  return (
    <Fragment>
      <div className="app-header-menu">
        <Popover
          id={id}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
        </Popover>
      </div>
    </Fragment>
  );
};

export default HeaderMenu;
