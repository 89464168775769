import React from "react";
import Acceso from "./Acceso";

const PuntoAcceso = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cedula = urlParams.get("id");
  return (
    <>
      <Acceso cedula={cedula} />
    </>
  );
};

export default PuntoAcceso;
