import { merge } from "remeda";
import { Resource } from "ghost-stories/dist/unions/Resource";
import { LISTAR_DATOS_USUARIO } from "../actionTypes";

const initial = {
  usuario: Resource.Empty({ ClientCI: "" }),
  form: {
    ClientID: "",
    ClientName: "",
    ClientLastName: "",
    ClientCI: "",
    ClientPIN: "",
    ClientAccessURL: "",
    ClientRequestedDocuments: [],
    VendorID: "",
  },
};

const clientes = (state = initial, action) => {
  switch (action.type) {
    case LISTAR_DATOS_USUARIO:
      return merge(state, { usuario: action.payload });
    default:
      return state;
  }
};

export default clientes;
