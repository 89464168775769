import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import { MostrarMensaje } from "../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackBar = () => {
  const classes = useStyles();
  const { open, mensaje, tipo } = useSelector((state) => state.snackBar);
  const dispatch = useDispatch();
  const handleClose = () =>
    dispatch(MostrarMensaje({ mensaje: "", tipo: "", open: false }));

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={tipo}>
          {mensaje}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
