import { merge } from "remeda";
import { Resource } from "ghost-stories/dist/unions/Resource";
import {
  EDITAR_DOCUMENTO,
  LIMPIAR_DOCUMENTOS,
  LISTA_DE_DOCUMENTOS,
} from "../actionTypes";

const initial = {
  lista: Resource.Empty({}),
  form: {
    DocumentID: "",
    DocumentName: "",
    DocumentState: "S",
  },
};

const documento = (state = initial, action) => {
  switch (action.type) {
    case LISTA_DE_DOCUMENTOS:
      return merge(state, { lista: action.payload });
    case EDITAR_DOCUMENTO:
      return merge(state, { form: action.payload });
    case LIMPIAR_DOCUMENTOS:
      return merge(state, { form: initial.form });
    default:
      return state;
  }
};

export default documento;
