import { combineReducers } from "redux";
import ThemeOptions from "./ThemeOptions";
import snackBar from "./snackBar";
import documentos from "./documentos";
import clientes from "./clientes";
import caso from "./caso";

export default combineReducers({
  ThemeOptions,
  snackBar,
  documentos,
  clientes,
  caso
});
