import {
  MOSTRAR_MENSAJE,
  LOGIN,
  LOGIN_GOOGLE,
  LOGIN_TWITTER,
  LOGIN_FACEBOOK,
  LISTA_DE_DOCUMENTOS,
  EDITAR_DOCUMENTO,
  MODIFICAR_DOCUMENTO,
  LISTAR_DATOS_USUARIO,
  MODIFICAR_DOCUMENTOS_USUARIO,
  CREAR_CASO,
} from "./actionTypes";

const create = (type) => (payload) => ({ type, payload });

export const Login = create(LOGIN);
export const LoginWithGoogle = create(LOGIN_GOOGLE);
export const LoginWithTwitter = create(LOGIN_TWITTER);
export const LoginWithFacebook = create(LOGIN_FACEBOOK);
export const MostrarMensaje = create(MOSTRAR_MENSAJE);

//DOCUMENTACIÓN
export const ListaDeDocumentos = create(LISTA_DE_DOCUMENTOS);
export const EditarDocumento = create(EDITAR_DOCUMENTO);
export const ModificarDocumento = create(MODIFICAR_DOCUMENTO);

//USUARIO
export const ListarDatosUsuario = create(LISTAR_DATOS_USUARIO);
export const ModificarDocumentosUsuario = create(MODIFICAR_DOCUMENTOS_USUARIO);

//CASO
export const CrearCaso = create(CREAR_CASO);
