import { BASE_URL, CASE_URL } from "../../constants/vars";
import {
  qsGetter,
  jsonPoster,
  jsonPut,
} from "ghost-stories/dist/fetch/helpers";

//DOCUMENTACIÓN
export const capturarDocumentos = qsGetter(`${BASE_URL}/documento`);
export const editarDocumento = jsonPut(`${BASE_URL}/documento`);

//CLIENTES
export const capturarDatosUsuario = jsonPoster(`${BASE_URL}/cliente/user`);

//USUARIO
export const editarDocumentoUsuario = jsonPut(`${BASE_URL}/cliente`);

//CASO
export const crearCaso = jsonPoster(`${CASE_URL}/az-fz-createcase`);
