export const MOSTRAR_MENSAJE = "SIGNAL/MOSTRAR_MENSAJE";
export const LOGIN_GOOGLE = "AUTHENTICATION/LOGIN/GOOGLE";
export const LOGIN_FACEBOOK = "AUTHENTICATION/LOGIN/FACEBOOK";
export const LOGIN_TWITTER = "AUTHENTICATION/LOGIN/TWITTER";
export const LOGIN = "AUTHENTICATION/LOGIN";

//DOCUMENTACIÓN
export const LISTA_DE_DOCUMENTOS = "ADMINISTRADOR/LISTA_DE_DOCUMENTOS";
export const EDITAR_DOCUMENTO = "ADMINISTRADOR/EDITAR_DOCUMENTO";
export const MODIFICAR_DOCUMENTO = "ADMINISTRADOR/MODIFICAR_DOCUMENTO";
export const LIMPIAR_DOCUMENTOS = "ADMINISTRADOR/LIMPIAR_DOCUMENTOS";

//USUARIO
export const LISTAR_DATOS_USUARIO = "USUARIO/LISTAR_DATOS_USUARIO";
export const MODIFICAR_DOCUMENTOS_USUARIO =
  "USUARIO/MODIFICAR_DOCUMENTOS_USUARIO";

export const CREAR_CASO = "CASO/CREAR_CASO";
