import { toParams, makeRequest } from "ghost-stories/dist/streams/resource";
import { map, filter, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { LISTA_DE_DOCUMENTOS, MODIFICAR_DOCUMENTO } from "../actionTypes";
import {
  ListaDeDocumentos,
  ModificarDocumento,
  MostrarMensaje,
} from "../actions";
import { capturarDocumentos, editarDocumento } from "../api";
import { CODIGO_LISTA_DOCUMENTACION } from "../../constants/codes";
import { Resource } from "ghost-stories/dist/unions/Resource";

const solicitarDocumentos = (action$) =>
  action$.pipe(
    ofType(LISTA_DE_DOCUMENTOS),
    toParams,
    makeRequest(capturarDocumentos),
    map(ListaDeDocumentos)
  );

const editarDocumentos = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTO),
    toParams,
    makeRequest(editarDocumento),
    map(ModificarDocumento)
  );

const onSaveEdit = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTO),
    filter((action) => Resource.isData(action.payload)),
    mergeMap(() => [
      ListaDeDocumentos(Resource.Query({ code: CODIGO_LISTA_DOCUMENTACION })),
      MostrarMensaje({
        open: true,
        mensaje: "Documento editado con éxito",
        tipo: "success",
      }),
    ])
  );

const onSaveEditError = (action$) =>
  action$.pipe(
    ofType(MODIFICAR_DOCUMENTO),
    filter((action) => Resource.isError(action.payload)),
    mergeMap(() => [
      ListaDeDocumentos(Resource.Query({ code: CODIGO_LISTA_DOCUMENTACION })),
      MostrarMensaje({
        open: true,
        mensaje: "No se pudo editar el documento",
        tipo: "error",
      }),
    ])
  );

export default combineEpics(
  solicitarDocumentos,
  editarDocumentos,
  onSaveEdit,
  onSaveEditError
);
