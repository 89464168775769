import React from "react";
import { Alert } from "@material-ui/lab";
import { LoadingUser } from "../utils/LoadingUser";

export const OnQuery = () => <Alert severity="info">Cargando...</Alert>;
export const OnEmpty = () => (
  <Alert severity="warning">No se obtuvieron resultados</Alert>
);
export const OnError = ({ messages }) => <div>Error: {messages.join("")}</div>;

export const OnQueryUser = () => <LoadingUser />;
